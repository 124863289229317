<ul class="navbar-nav me-auto mb-2 mb-lg-0" [ngClass]="customClass ? customClass + '-nav' : ''">
	<div ngbDropdown class="nav-item">
		<a ngbDropdownToggle href="javascript:void(0)" class="nav-link" role="button">
			Business Solutions
		</a>
		<div ngbDropdownMenu>
			<a ngbDropdownItem [routerLink]="['/business-solutions', 'energy-procurement-platform']" (click)="closeCanvas()"><fa-icon [icon]="['fas', 'caret-right']"></fa-icon>Energy Procurement Platform</a>
			<a ngbDropdownItem [routerLink]="['/business-solutions', 'market-access']" (click)="closeCanvas()"><fa-icon [icon]="['fas', 'caret-right']"></fa-icon>Complete Market Access</a>
			<a ngbDropdownItem [routerLink]="['/business-solutions', 'pricing-and-support']" (click)="closeCanvas()"><fa-icon [icon]="['fas', 'caret-right']"></fa-icon>Dedicated Broker Support</a>
			<a ngbDropdownItem [routerLink]="['/business-solutions', 'market-analytics']" (click)="closeCanvas()"><fa-icon [icon]="['fas', 'caret-right']"></fa-icon>Market Analytics</a>
			<a ngbDropdownItem [routerLink]="['/business-solutions', 'crm-and-reporting']" (click)="closeCanvas()"><fa-icon [icon]="['fas', 'caret-right']"></fa-icon>CRM and Reporting</a>
		</div>
	</div>
	<div ngbDropdown class="nav-item">
		<a ngbDropdownToggle href="javascript:void(0)" class="nav-link" role="button">
			Our Platform
		</a>
		<div ngbDropdownMenu>
			<a ngbDropdownItem [routerLink]="['/our-platform', 'pricing-tools']" (click)="closeCanvas()"><fa-icon [icon]="['fas', 'caret-right']"></fa-icon>Pricing Tools</a>
			<a ngbDropdownItem [routerLink]="['/our-platform', 'customer-engagement']" (click)="closeCanvas()"><fa-icon [icon]="['fas', 'caret-right']"></fa-icon>Customer Engagement</a>
			<a ngbDropdownItem [routerLink]="['/our-platform', 'market-intelligence']" (click)="closeCanvas()"><fa-icon [icon]="['fas', 'caret-right']"></fa-icon>Market Intelligence</a>
			<a ngbDropdownItem [routerLink]="['/our-platform', 'ecommerce-api']" (click)="closeCanvas()"><fa-icon [icon]="['fas', 'caret-right']"></fa-icon>E-Commerce API</a>
		</div>
	</div>
	<div ngbDropdown class="nav-item">
		<a ngbDropdownToggle href="javascript:void(0)" class="nav-link" role="button">
			About Us
		</a>
		<div ngbDropdownMenu>
			<a ngbDropdownItem [routerLink]="['/about-us', 'our-story']" (click)="closeCanvas()"><fa-icon [icon]="['fas', 'caret-right']"></fa-icon>Our Story</a>
			<a ngbDropdownItem [routerLink]="['/about-us', 'our-team']" (click)="closeCanvas()"><fa-icon [icon]="['fas', 'caret-right']"></fa-icon>Our Team</a>
			<a ngbDropdownItem [routerLink]="['/about-us', 'news-and-resources']" (click)="closeCanvas()"><fa-icon [icon]="['fas', 'caret-right']"></fa-icon>News &amp; Resources</a>
		</div>
	</div>
	<li class="nav-item">
		<a class="nav-link" [routerLink]="['/contact-us']" (click)="closeCanvas()">Contact Us</a>
	</li>
</ul>
<ul class="navbar-nav mb-2 mb-lg-0" [ngClass]="customClass ? customClass + '-nav' : ''">
	<li class="nav-item pt-1" id="mainNavigationDemo">
		<a href="javascript:void(0);" pkWebCalendlyWidget class="btn btn-secondary nav-btn schedule-demo" id="scheduleDemoHeader">Schedule a Demo</a>
	</li>
	<li class="nav-item">
		<a class="nav-link" [attr.href]="dashboardLogin" target="_blank">Dashboard Login</a>
	</li>
</ul>
