import { Component, OnInit } from '@angular/core';

import { AppStateService } from './core/app-state.service';

@Component({
	selector: 'pk-web-root',
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

	private navbarClass: string;

	constructor(private appStateService: AppStateService) { }

	ngOnInit(): void {
		this.handleNavbarUpdateOnScrollChange();
		this.appStateService.onSetNavbarClass.subscribe(update => {
			// we only want to know what the initial page state was so we can determine which header class to reset in
			// handleNavbarUpdateOnScrollChange below
			if (update.isInitial) {
				this.navbarClass = update.navbarClass;
			}
		});
	}

	private handleNavbarUpdateOnScrollChange(): void {
		if (
			"IntersectionObserver" in window &&
			"IntersectionObserverEntry" in window &&
			"intersectionRatio" in window.IntersectionObserverEntry.prototype
		) {
			let observer = new IntersectionObserver(entries => {
				if (entries[0].boundingClientRect.y < 0) {
					this.appStateService.setNavbarClass('normal-header', false);
				} else {
					if (this.navbarClass === 'light-header') {
						this.appStateService.setNavbarClass('light-header', false);
					} else if (this.navbarClass === 'normal-nobg-header') {
						this.appStateService.setNavbarClass('normal-nobg-header', false);
					}
				}
			});
			observer.observe(document.querySelector("#top-of-site-pixel-anchor") as Element);
		}
	}
}
