import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./homepage/homepage.module').then(m => m.HomepageModule),
		data: { preload: true },
	},
	{
		path: 'business-solutions',
		loadChildren: () => import('./business-solutions/business-solutions.module').then(m => m.BusinessSolutionsModule),
		data: { preload: true },
	},
	{
		path: 'our-platform',
		loadChildren: () => import('./our-platform/our-platform.module').then(m => m.OurPlatformModule),
		data: { preload: true },
	},
	{
		path: 'about-us',
		loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsModule),
		data: { preload: true },
	},
	{
		path: 'contact-us',
		loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsModule),
		data: { preload: true },
	},
	{
		path: 'privacy-policy',
		loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
		data: { preload: true },
	},
	{
		path: 'landing',
		loadChildren: () => import('./landing-pages/landing-pages.module').then(m => m.LandingPagesModule),
		data: { preload: true },
	},
	{
		path: '**',
		redirectTo: '',
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			anchorScrolling: 'enabled',
			scrollPositionRestoration: 'enabled',
		}),
	],
	exports: [
		RouterModule
	]
})
export class AppRoutingModule { }
