import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';

import { HttpRequestInterceptor } from '../shared/interceptors/http-request-interceptor';
import { SharedModule } from '../shared/shared.module';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NavbarNavComponent } from './navbar/navbar-nav/navbar-nav.component';

@NgModule({
	declarations: [
		NavbarComponent,
		FooterComponent,
  NavbarNavComponent,
	],
	exports: [
		NavbarComponent,
		FooterComponent,
	],
	imports: [
		CommonModule,
		RouterModule,
		SharedModule,
	]
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		if (parentModule) {
			throw new Error('CoreModule is already loaded. Import it in the AppModule only');
		}
	}

	static forRoot(): ModuleWithProviders<CoreModule> {
		return {
			ngModule: CoreModule,
			providers: [
				{ provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
			],
		};
	}
}
