import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const clonedRequest = req.clone({
			headers: req.headers.set('Authorization', 'Bearer ' + environment.apiKey),
		});

		return next.handle(clonedRequest)
			.pipe(
				map((result: HttpEvent<any>) => {
					if (result instanceof HttpResponse && result.body?.errors) {
						throw result;
					}
					return result;
				}),
				catchError(
					(error: any, caught: Observable<HttpEvent<any>>) => {
						if (error.body && error.body.errors && error.body.errors.length) {
							return throwError(() => new Error(error.body.errors.map((e: any) => e.message).join(',')));
						}
						return throwError(() => error);
					},
				),
			);
	}
}
