import { Component, EventEmitter, Input, Output } from '@angular/core';

import { environment } from 'src/environments/environment';

@Component({
	selector: 'pk-web-navbar-nav, [pk-web-navbar-nav]',
	templateUrl: './navbar-nav.component.html',
	styleUrl: './navbar-nav.component.scss'
})
export class NavbarNavComponent {

	@Input() customClass: string;
	@Output() navigated: EventEmitter<boolean> = new EventEmitter();

	get dashboardLogin() {
		return environment.dashboardLogin;
	}

	public closeCanvas(): void {
		this.navigated.emit(true);
	}
}
