<footer class="container-fluid">
	<div class="row maxwidth-1264 mx-auto">
		<div class="col">
			<div class="row">
				<div class="col-auto col-sm-12 col-xl me-lg-5 pt-3 pt-lg-0 mx-auto mx-lg-0 mb-4 mb-xl-0">
					<p class="footer-logo">
						<a [routerLink]="['/']"><img src="//assets.powerkiosk.com/web/images/pk-logo-horiz-fullColor-white.svg" class="Power Kiosk"></a>
					</p>
					<p class="ps-5">&copy; {{ year }} All Rights Reserved</p>
				</div>
				<div class="col-12 col-sm-6 col-lg mb-4 mb-lg-0 pt-lg-3">
					<h4>Business Solutions</h4>
					<p><a [routerLink]="['/business-solutions', 'energy-procurement-platform']" class="footer-link">Energy Procurement Platform</a></p>
					<p><a [routerLink]="['/business-solutions', 'market-access']" [routerLink]="['/business-solutions', 'market-access']" class="footer-link">Complete Market Access</a></p>
					<p><a [routerLink]="['/business-solutions', 'pricing-and-support']" class="footer-link">Dedicated Broker Support</a></p>
					<p><a [routerLink]="['/business-solutions', 'market-analytics']" class="footer-link">Market Analytics</a></p>
					<p><a [routerLink]="['/business-solutions', 'crm-and-reporting']" class="footer-link">CRM and Reporting</a></p>
				</div>
				<div class="col-12 col-sm-6 col-lg pt-lg-3 mb-4 mb-lg-0">
					<h4>Our Platform</h4>
					<p><a [routerLink]="['/our-platform', 'pricing-tools']" class="footer-link">Pricing Tools</a></p>
					<p><a [routerLink]="['/our-platform', 'customer-engagement']" class="footer-link">Customer Engagement</a></p>
					<p><a [routerLink]="['/our-platform', 'market-intelligence']" class="footer-link">Market Intelligence</a></p>
					<p><a [routerLink]="['/our-platform', 'ecommerce-api']" class="footer-link">E-Commerce API</a></p>
				</div>
				<div class="col-12 col-sm-6 col-lg-auto pt-lg-3">
					<h4>About Us</h4>
					<p><a [routerLink]="['/about-us', 'our-story']" class="footer-link">Our Story</a></p>
					<p><a [routerLink]="['/about-us', 'our-team']" class="footer-link">Our Team</a></p>
					<p><a [routerLink]="['/about-us', 'news-and-resources']" class="footer-link">News &amp; Resources</a></p>
					<h4 class="mt-4 mb-2 no-underline"><a [routerLink]="['contact-us']">Contact Us</a></h4>
          <p><a [routerLink]="['/privacy-policy']" class="footer-link">Privacy Policy</a></p>
				</div>
				<div class="col-12 col-sm-6 col-lg-auto pt-lg-3">
					<a href="https://www.linkedin.com/company/power-kiosk/" class="footer-link"><fa-icon [icon]="['fab', 'linkedin']"></fa-icon></a>
				</div>
			</div>
		</div>
	</div>
</footer>
