import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import * as moment from 'moment-timezone';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { GraphqlModule } from './graphql/graphql.module';
import { GlobalErrorHandler } from './shared/error-handlers/global-error-handler';
import { HelperService } from './shared/helper.service';

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		CoreModule.forRoot(),
		GraphqlModule,
	],
	providers: [{ provide: ErrorHandler, useClass: GlobalErrorHandler }],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor() {
		moment.tz.setDefault(environment.timezone);
		environment.apiKey = (environment.apiKeys as any)[this.getRequester(window.location.origin)];

		if (
			window.location.origin.indexOf('test.powerkiosk.com') !== -1 ||
			window.location.origin.indexOf('localhost') !== -1
		) {
			(window as any)['ga-disable-GTM-PN7MVM2'] = true;
		}
	}

	private getRequester(origin: string): string {
		return HelperService.removeLastCharacter(origin, '/').replace('www.', '').split('/').splice(0, 3).join('/').toLowerCase();
	}
}
