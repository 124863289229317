<header class="navbar fixed-top navbar-expand-xl" [ngClass]="navbarClass" *ngIf="navbarClass">
	<nav class="container-fluid maxwidth-1264 mx-auto">
		<a class="navbar-brand" [routerLink]="['/']">
			<span class="dark-logo">
				<img src="//assets.powerkiosk.com/web/images/pk-icon-fullColor.svg" alt="Power Kiosk" class="d-inline d-md-none small-logo dark-logo">
				<img src="//assets.powerkiosk.com/web/images/pk-logo-horiz-fullColor.svg" alt="Power Kiosk" class="d-none d-md-inline big-logo dark-logo">
			</span>
			<span class="light-logo">
				<img src="//assets.powerkiosk.com/web/images/pk-icon-fullColor-white.svg" alt="Power Kiosk" class="d-inline d-md-none small-logo light-logo">
				<img src="//assets.powerkiosk.com/web/images/pk-logo-horiz-fullColor-white.svg" alt="Power Kiosk" class="d-none d-md-inline big-logo light-logo">
			</span>
		</a>
		<button class="navbar-toggler" type="button" (click)="openCanvas(canvasContent)">
			<fa-icon [icon]="['fas', 'bars']"></fa-icon>
		</button>
		<ng-template #canvasContent let-offcanvas>
			<div class="offcanvas-header">
				<span class="offcanvas-title" id="offcanvasNavbarLabel">
					<button type="button" class="btn-close text-reset" (click)="offcanvas.close('Close click')" aria-label="Close"></button>
				</span>
			</div>
			<div class="offcanvas-body">
				<pk-web-navbar-nav [customClass]="navbarClass" (navigated)="offcanvas.close('Navigated')"></pk-web-navbar-nav>
			</div>
		</ng-template>
		<div class="offcanvas">
			<pk-web-navbar-nav [customClass]="navbarClass" class="offcanvas-body"></pk-web-navbar-nav>
		</div>
	</nav>
</header>
