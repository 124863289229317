import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

import { AppStateService } from '../app-state.service';

@Component({
	selector: 'pk-web-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {

	public navbarClass: string;

	constructor(
		private appStateService: AppStateService,
		private offcanvasService: NgbOffcanvas,
	) {

	}

	ngOnInit(): void {
		this.appStateService.onSetNavbarClass.subscribe(update => {
			this.navbarClass = update.navbarClass;
		});
	}

	public async openCanvas(content: TemplateRef<any>): Promise<void> {
		this.offcanvasService.open(content, { ariaLabelledBy: 'offcanvasNavbarLabel', position: 'end' });
	}
}
