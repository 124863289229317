import { Component, OnInit } from '@angular/core';

import moment from 'moment-timezone';

@Component({
	selector: 'pk-web-footer',
	templateUrl: './footer.component.html',
	styleUrl: './footer.component.scss'
})
export class FooterComponent implements OnInit {

	public year: number;

	ngOnInit(): void {
		this.year = moment().year();
	}
}
